.tooki__banner{
    background-image: url(../../Assets/ornaments-4927258_1920.jpg);
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;

}
.tooki__banner-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    justify-content: center;
    background-color: whitesmoke;
    /* mix-blend-mode: screen; */
}
.tooki__banner-container_item1{
    display: flex;
    flex-direction: row;
    margin-top: 5rem;
    align-items: center;
    justify-content: space-between;
    padding: 0 55px;
    margin-bottom: 5rem;
}
.tooki_banner-container_item1-detail{
    width: 100%;
}
.tooki_banner-container_item1-detail h1{
    font-size: 46px;
    font-weight: 700;
    width: 100%  ;
}
.tooki_banner-container_item1-detail p{
    width: 80%;
    font-size: 18px;
    /* line-height: 100%; */
}
/* .tooki_banner-container_item1-detail-div{

} */
.tooki_banner-container_item1-detail-div .btn1{
    padding: 20px;
    background-color: #cc2229;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    width: 80%;
    cursor: pointer;
}
.tooki_banner-container_item1-detail-div .btn1:hover{
    background-color: #505e86;
}
/* .coolBeans{
  border: 2px solid currentColor;
  border-radius: 3rem;
  color: yellow;
  font-family: roboto;
  font-size: 4rem;
  font-weight: 100;
  overflow: hidden;
  padding: 1rem 2rem;
  position: relative;
  text-decoration: none;
  transition : .2s transform ease-in-out;
  will-change :transform;
  z-index: 0
  
  &::after
    background-color yellow
    border-radius 3rem
    content: ''
    display block
    height 100%
    width 100%
    position absolute
    left 0
    top 0
    transform translate(-100%, 0) rotate(10deg)
    transform-origin top left
    transition .2s transform ease-out
    will-change transform
    z-index -1
    
  &:hover::after
    transform translate(0, 0)
    
  &:hover
    border 2px solid transparent
    color indigo
    transform scale(1.05)
    will-change transform
} */
    
/* .tooki_banner-container_item1-detail-div .btn2{
    padding: 20px;
    background-color: white;
    border: none;
    border-radius: 5px;
    color: #505e86;
    margin-left: 1rem;
    font-size: 16px;
} */
.rating{
    display: flex;
    margin-top: 1.5rem;
}
.rating p{
    width: 120px;
    font-size: 15px;
}
.rating img{
    width: 100px;
    height: 60px;
}
.rating :nth-child(3){
    color: #505e86;
    margin-left: 0.2rem
}
.tooki__banner-container_two{
    width: 100%;
    background-color: #505e86;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tooki__banner-container_two-content{
    display: flex;
}
.tooki__banner-container_two-content :nth-child(2){
    margin-left: 1.5rem;
}
.tooki__banner-container_two-content-div {
    margin-left: 1.5rem;
}
.tooki__banner-container_two-content-div{
    color: gray;
}
@media screen and (max-width:1100px) {
    .tooki_banner-container_item1-video .video{
        width: 400px;
        height: 280px;
    }
}
@media screen and (max-width:850px) {
    .tooki__banner-container_item1{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    .tooki_banner-container_item1-video{
        margin-top: 1.5rem;
    }
    .tooki_banner-container_item1-video .video{
        width: 560px;
        height: 315px;
    }
    .tooki_banner-container_item1-detail{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .tooki__banner-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        justify-content: center;
        background-color: whitesmoke;
        /* mix-blend-mode: screen; */
    }
    .tooki_banner-container_item1-detail h1{
        font-size: 12px;
        width: 100%  ;
        margin-left: 3rem;
    }
    .tooki_banner-container_item1-detail p{
        width: 80%;
        font-size: 15px !important;
    }
    .tooki_banner-container_item1-detail-div .btn1{
        padding: 20px;
        background-color: #cc2229;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 16px;
        margin: 0 10px 0 10px;
        width: 90vw!important;
        cursor: pointer;
    }
}
@media screen and (max-width:670px){
    .tooki_banner-container_item1-video .video{
        width: 400px;
        height: 280px;
    }
    .tooki__banner-container_item1{
        padding: 0;
        margin-top: 3.5rem;
    }
    .tooki__banner-container_two-content h1{
        display: none;
    }
    .tooki__banner-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        justify-content: center;
        background-color: rgba(243, 241, 241, 0.20);
        mix-blend-mode: screen;

    }

}
@media screen and (max-width:500px){
    .tooki_banner-container_item1-video .video{
        width: 300px;
        height: 180px;
    }
    .tooki_banner-container_item1-detail h1{
        font-size: 30px;
    }
    .tooki_banner-container_item1-detail p{
        font-size: 18px;
    }
    .tooki_banner-container_item1-detail-div .btn1{
        padding: 15px;
        font-size: 15px;
    }
    .tooki_banner-container_item1-detail-div .btn2{
        padding: 15px;
        font-size: 15px;
    }
    .rating{
        display: none;
    }
    .tooki__banner-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        justify-content: center;
        background-color: whitesmoke;
        /* mix-blend-mode: screen; */
    }
}
@media screen and (max-width:300px){
    .tooki_banner-container_item1-video .video{
        width: 200px;
        height: 130px;
    }
    .tooki__banner-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        justify-content: center;
        background-color: whitesmoke;
        /* mix-blend-mode: screen; */
    }
}

